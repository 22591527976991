// App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LogIn from './components/Login';
import Dashboard from './components/Dashboard';
import SignUp from './components/SignUp';
import Sensor from  './components/Sensor_id';
import Profile from './components/Profile/Profile'
import Admin from './components/Admin/admin'


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LogIn />} />
        <Route path="/SignUp" element={<SignUp />} />
        <Route path="/Sensor" element={<Sensor />} />
        <Route path="/Profile" element={<Profile />} />
        <Route path="/Admin" element={<Admin />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
    </Router>
  );
}

export default App;
