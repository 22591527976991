import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Dropdown } from 'react-bootstrap';
import Chart from 'react-apexcharts';
import Clock from 'react-live-clock';
import moment from 'moment';
import 'moment-timezone';
import { getFromAPI } from './Utils/utils.js';
import { useNavigate } from "react-router-dom";
import Sidebar from './SideBar.js';

export default function Dashboard() {
  const [sensorData, setSensorData] = useState([]);
  const [sensors, setSensors] = useState([]);
  const [selectedSensorId, setSelectedSensorId] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [dataPolling, setDataPolling] = useState(30); // Default interval is 30 seconds
  const navigate = useNavigate();

  useEffect(() => {
    fetchSensors();
  }, []);

  useEffect(() => {
    if (selectedSensorId) {
      fetchData();
      const interval = setInterval(fetchData, dataPolling * 1000);
      return () => clearInterval(interval);
    }
  }, [selectedSensorId]);

  async function fetchSensors() {
    try {
      const storedUserId = localStorage.getItem('user_id');
      const isAdmin = localStorage.getItem('isAdmin') === 'True';
      let sensordata;
      //const sensordata = await getFromAPI(`usersensor/?user_id=${encodeURIComponent(storedUserId)}`);  
       if(isAdmin) 
        { const sensordata = await getFromAPI(`usersensor/`);
         setSensors(sensordata);
        }
        else
        { const sensordata = await getFromAPI(`usersensor/?user_id=${encodeURIComponent(storedUserId)}`);
        setSensors(sensordata);
       }     
      // Sort sensors alphabetically by sensor_name
      sensordata.sort((a, b) => a.sensor_name.localeCompare(b.sensor_name));
      const dataPolling = sensordata.length > 0 ? sensordata[0].data_polling : 30; // Default to 30 seconds if data_polling is not available
      setSensors(sensordata);
      if (sensordata.length > 0) {
        setSelectedSensorId(sensordata[0].sensor_id);
        setDataPolling(dataPolling); // Set the dataPolling state
      }
    } catch (error) {
      console.error("Error fetching sensor data:", error);
    }
  }


  async function fetchData() {
    if (!selectedSensorId) return;

    try {
      const response = await getFromAPI(`sensordata/?sensor_id=${encodeURIComponent(selectedSensorId)}`);
      setSensorData(response);
      if (response && response.length > 0) {
        const latestRecord = response[response.length - 1];
        const latestReadingTime = new Date(latestRecord.reading_time);
        const currentTime = new Date();
        const timeDifference = currentTime.getTime() - latestReadingTime.getTime();
        const oneHourInMilliseconds = 60 * 60 * 1000; // 1 hour in milliseconds

        if (timeDifference <= oneHourInMilliseconds) {
          setIsConnected(true);
        } else {
          setIsConnected(false);
        }
      }
      else {
        setIsConnected(false); // Set to disconnected if no data is available
      }

    } catch (error) {
      console.error("Error fetching sensor data:", error);
    }
  }

  const handleSensorChange = (sensorId) => {
    setSelectedSensorId(sensorId);
  };
  
  const handleLogout = () => {
    navigate('/');
  };

  // Logic to determine air quality, alcohol level, and CO2 level
  let airQuality = "";
  let co2Level = "";
  let alcoholLevel = "";
  const temperature = sensorData.length > 0 ? sensorData[sensorData.length - 1].value1 : 0;
  const humidity = sensorData.length > 0 ? sensorData[sensorData.length - 1].value2 : 0;
  const value3 = sensorData.length > 0 ? sensorData[sensorData.length - 1].value3 : 0

  if(value3.length>0){
  if (value3 < 600) {
    airQuality = "Excellent!";
  } else if (value3 > 700 && value3 < 800) {
    airQuality = "Good!";
  } else if (value3 > 900 && value3 < 1000) {
    airQuality = "Fair!";
  } else if (value3 > 1100 && value3 < 1500) {
    airQuality = "Mediocre!";
  } else {
    airQuality = "Toxic / Bad!";
  }
}
  else
  {
     airQuality = "";
  }

  if(value3.length>0){
  if (value3 < 400) {
    co2Level = "Atmospheric";
  } else if (value3 > 350 && value3 < 450) {
    co2Level = "Average";
  } else if (value3 === 1000) {
    co2Level = "Maximum";
  } else if (value3 > 2000) {
    co2Level = "Dangerous";
  }}
  else
  {
    co2Level = "";
  }

  if(value3.length>0){
  if (value3 < 400) {
    alcoholLevel = "Normal!";
  } else if (value3 > 400) {
    alcoholLevel = "Harmful";
  }
  }
  else
  {
     alcoholLevel = '';   
  }

  const timezone = moment.tz.guess(); // Get client's timezone
  const timeZoneAbbreviation = moment.tz(timezone).format('z');

  // temperatureChart configuration
  const temperatureChart = {
    series: [temperature],
    chart: {
      type: 'radialBar',
      offsetY: -20,
      sparkline: {
        enabled: true
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -150,
        endAngle: 150,
        track: {
          show: false,
        },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            offsetY: -10,
            formatter: function () {
              return temperature + "°C";
            },
            fontSize: '36px',
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 500,
            color: '#232A34',
          }
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: "horizontal",
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        colorStops: [{
          offset: 0,
          color: '#00C269'
        }, {
          offset: 50,
          color: '#F5CE44'
        }, {
          offset: 100,
          color: '#F63E3E'
        }],
      }
    },
    stroke: {
      lineCap: "round",
    },
    labels: ['Temperature'],
  };

  const humidityChart =  {
    series: [humidity],
    chart: {
      type: 'radialBar',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 225,
        hollow: {
          margin: 0,
          size: '110%',
          background: '#fff',
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: 'front',
          dropShadow: {
            enabled: false,
          }
        },
        track: {
          show: false,
          background: '#e7e7e7',
          strokeWidth: '100%',
          margin: -40, // margin is in pixels
          dropShadow: {
            enabled: false,
          }
        },
  
        dataLabels: {
          show: true,
          value: {
            formatter: function (val) {
              return parseInt(val) + '%'; // Added '%' symbol after the value
            },
            fontFamily: 'Poppins", sans-serif',
            color: '#232A34',
            fontSize: '36px',
            fontWeight: 600,
            show: true,
          },
          name: {
            offsetY: -10,
            show: true,
            fontFamily: 'Poppins", sans-serif',
            color: '#808A98',
            fontWeight: 400,
            fontSize: '17px'
          }
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        shadeIntensity: 0.5,
        colorStops: [{
          offset: 0,
          color: '#00C269'
        }, {
          offset: 100,
          color: '#3454FA'
        }],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100]
      }
    },
    stroke: {
      lineCap: 'round'
    },
    labels: ['Humidity'],
  };
  const last5readingtime = Array.isArray(sensorData) ? sensorData.map(data => data.reading_time) : []
  const value3Data = Array.isArray(sensorData) ? sensorData.map(data => data.value3) : [];
  const lastFiveValue3Data = value3Data.slice(-5);
  const categories = last5readingtime.slice(-5);
  const formattedCategories = categories.map(timestamp => moment(timestamp).format('hh:mm A')); // Format time in AM/PM format

  // ppmChart
  const ppmChart = {
    series: [{
      name: 'PPM',
      data: lastFiveValue3Data // Ensure value3 is an array of numbers
    }],
    chart: {
      height: 350,
      type: 'area',
      toolbar: {
        show: false
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: 2,
      borderColor: '#3454FA'
    },
    xaxis: {
      categories: formattedCategories,
      labels: {
        style: {
          colors: '#808A98',
          fontFamily: 'Poppins", sans-serif',
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: '#808A98',
          fontFamily: 'Poppins", sans-serif',
        }
      }
    },
    grid: {
      borderColor: '#EDF0F4',
    },
    fill: {
      type: 'gradient',
      gradient: {
        type: "vertical",
        gradientToColors: '#3454FA',
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0,
        stops: [0, 100]
      }
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm'
      }
    },
    colors: ['#3454FA'],
  };

  const value4Data = Array.isArray(sensorData) ? sensorData.map(data => data.value4) : [];
  const lastFiveValue4Data = value4Data.slice(-5);
  const categories1 = Array.isArray(sensorData) ? sensorData.map(data => data.reading_time) : [];
  const formattedCategories1 = categories1.map(timestamp => moment(timestamp).format('hh:mm A')); // Format time in AM/PM format
   // rZeroChart
   const rZeroChart =  {
    series: [{
      name: 'Rzero',
      data: lastFiveValue4Data.map((value, index) => ({ x: formattedCategories[index], y: value }))
    }],
    options: {
   chart: {
    type: 'bar',
    stacked: true,
    toolbar: {
      show: false,
    },
  },
  grid: {
    show: true,
    borderColor: '#EDF0F4',
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      columnWidth: '14px',
      horizontal: false,
      endingShape: "rounded",
      borderRadius: 4,
      
    }
  },
  xaxis: {
    categories:[],
    axisTicks: {
      show: false,
    },
    labels: {
      style: {
        colors: '#808A98',
        fontFamily: 'Poppins", sans-serif',
      }
    }
  },
  yaxis: {
    labels: {
      style: {
        colors: '#808A98',
        fontFamily: 'Poppins", sans-serif',
      }
    }
  },
  tooltip: {
    x: {
      format: 'dd/MM/yy HH:mm'
    }
  },
  colors: ['#3454FA']
}
};

  return (
    <>
      <div className='d-flex'>
        <Sidebar />
        <div className='main-content'>
          <div className='page-wrapper'>
            <div className='topbar'>
              <Container fluid>
                <Row>
                  <Col md={12}>
                    <div className='d-flex justify-content-between align-items-center'>
                      <h2 className='mb-0'>Air Pollution Dashboard</h2>
                      <div className='d-flex align-items-center'>
                        <Clock className='text-uppercase' format={'hh:mm a'} ticking={true} timezone={timezone} />
                        <span>&nbsp;{timeZoneAbbreviation}</span>
                        <button className="btn btn-primary logout-button ms-3" onClick={handleLogout}>
                          <i className="bi bi-box-arrow-in-right me-2"></i>Logout
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="page-content">
              <Container fluid>
                <Row>
                  <Col lg={6} xxl={4}>
                    <Card>
                      <Card.Body>
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <div className='avarat-icon icon-soft-success'>
                              <i className="bi bi-broadcast"></i>
                            </div>
                          </div>
                          <div className="flex-grow-1 ms-4">
                          <Dropdown>
                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                              {selectedSensorId
                                ? sensors.find(sensor => sensor.sensor_id === selectedSensorId)?.sensor_name
                                : "Select Sensor"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
                              {sensors.map(sensor => (
                                <Dropdown.Item
                                  key={sensor.sensor_id}
                                  onClick={() => handleSensorChange(sensor.sensor_id)}
                                >
                                  {sensor.sensor_name}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                            <h2 className={isConnected ? 'text-success mb-0' : 'text-danger mb-0'}>
                              {isConnected ? 'Connected' : 'Disconnected'}
                            </h2>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                    <Card>
                      <Card.Body>
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <div className='avarat-icon icon-soft-primary'>
                              <i className="bi bi-geo-alt"></i>
                            </div>
                          </div>
                          <div className="flex-grow-1 ms-4">
                            <h4 className='text-muted fw-normal mb-2'>Location</h4>
                            <h2 className='text-success mb-0'>{sensorData.length > 0 ? sensorData[sensorData.length - 1].location : ''}</h2>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg={6} xxl={4} className='mb-4'>
                    <Card className='h-100 mb-0'>
                      <Card.Body>
                        <Card.Title>Temperature</Card.Title>
                        <div className="dash-chart">
                          <Chart options={temperatureChart} series={temperatureChart.series} type="radialBar" height={420} />
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg={6} xxl={4} className='mb-4'>
                    <Card className='h-100 mb-0'>
                      <Card.Body>
                        <Card.Title>Humidity</Card.Title>
                        <div className="dash-chart">
                          <Chart options={humidityChart} series={humidityChart.series} type="radialBar" height={285} />
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg={6} xxl={4} className='mb-4'>
                    <Card className='h-100 mb-0'>
                      <Card.Body>
                        <Card.Title>PPM</Card.Title>
                        <div className='d-flex justify-content-between align-items-center py-4 px-4 border rounded-3 bg-light shadow-none mb-3'>
                          <h5 className='fs-24 mb-0 py-2'>Air Quality</h5>
                          <p className='mb-0 fs-20 text-warning'>{airQuality}</p>
                        </div>
                        <div className='d-flex justify-content-between align-items-center py-4 px-4 border rounded-3 bg-light shadow-none mb-3'>
                          <h5 className='fs-24 mb-0 py-2'>CO<sub>2</sub> Level</h5>
                          <p className='mb-0 fs-20 text-danger'>{co2Level}</p>
                        </div>
                        <div className='d-flex justify-content-between align-items-center py-4 px-4 border rounded-3 bg-light shadow-none'>
                          <h5 className='fs-24 mb-0 py-2'>Alcohol Level</h5>
                          <p className='mb-0 fs-20 text-success'>{alcoholLevel}</p>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg={6} xxl={4} className='mb-4'>
                    <Card className='h-100 mb-0'>
                      <Card.Body>
                        <Card.Title>PPM</Card.Title>
                        <h2>{sensorData.length > 0 ? sensorData[sensorData.length - 1].value3 : ''}</h2>
                        <div className="dash-chart">
                          {value3Data.length > 0 && (
                            <Chart options={ppmChart} series={ppmChart.series} type="area" height={260} />
                          )}
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg={6} xxl={4} className='mb-4'>
                  <Card className='h-100 mb-0'>
                    <Card.Body>
                      <Card.Title>RZERO</Card.Title>
                      <h2>{sensorData.length > 0 ? sensorData[sensorData.length - 1].value4 : ''}</h2>
                      <div className="dash-chart">
                        {lastFiveValue4Data.length > 0 && (
                          <Chart options={rZeroChart.options} series={rZeroChart.series} type="bar" height={260} />
                        )}
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
