import React, { useState, useEffect, useRef } from "react";
import { Table, Form, Button, Card, Container, Row, Col, Modal } from "react-bootstrap";
import { getFromAPI, postToAPI, putToAPI, deleteFromAPI, showAlert } from '../Utils/utils.js';
import Sidebar from '../SideBar.js';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from "datatables.net-dt";
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import JSZip from "jszip";
import 'react-toastify/dist/ReactToastify.css';

export default function Profile() {
    const [sensors, setSensors] = useState([]);
    const [showModalOne, setShowModalOne] = useState(false);
    const handleCloseModalOne = () => {
        setShowModalOne(false);
        setInputData({
            sensor_id: '',
            sensor_name: '',
            data_polling: '',
            data_posting: '',
        });
    };
    const handleShowModalOne = () => setShowModalOne(true);

    const [inputData, setInputData] = useState({
        sensor_id: '',
        sensor_name: '',
        data_polling: '',
        data_posting: '',
    });

    const [editMode, setEditMode] = useState(false); // Track whether we're in edit mode
    const [EditedData, setEditedData] = useState({
        SensorID: '',
    });

    const navigate = useNavigate();
    const tableRef = useRef(null); // Ref for DataTable instance

    const First_Name = localStorage.getItem('First_Name');
    const Last_Name = localStorage.getItem('Last_Name');
    const email = localStorage.getItem('email');
    const Phone = localStorage.getItem('Phone');
    const storedUserId = localStorage.getItem('user_id');
    const isAdmin = localStorage.getItem('isAdmin') === 'True';


    useEffect(() => {
        fetchSensors();
    }, []);


    async function fetchSensors() {
        try {
            let sensordata;
            if(isAdmin) {
                sensordata = await getFromAPI(`usersensor/`);
            } else {
                sensordata = await getFromAPI(`usersensor/?user_id=${encodeURIComponent(storedUserId)}`);
            }
            setSensors(sensordata);
        } catch (error) {
            console.error("Error fetching sensor data:", error);
        }
    }

    useEffect(() => {
        // Initialize or update DataTable when sensors change
        if ($.fn.dataTable.isDataTable(tableRef.current)) {
            $(tableRef.current).DataTable().destroy();
        }
        initDataTable(); // Initialize DataTable after sensors data is fetched
    }, [sensors]);


    function initDataTable() {
        const table = $(tableRef.current).DataTable({
            dom: '<"d-flex justify-content-between mb-3"lf>t<"d-flex justify-content-between mb-3"ip>',
            language: {
                search: '',
                searchPlaceholder: 'Search...',
                lengthMenu: 'Show _MENU_ entries per page  ',
                paginate: {
                    previous: '«',
                    next: '»',
                },
            },
            data: sensors,
            columns: [
                { data: 'sensor_id' },
                { data: 'sensor_name' },
                { data: 'data_posting' },
                { data: 'data_polling' },
                {
                    data: null,
                    render: function (data, type, row) {
                        return `
                            <div class="d-flex">
                                <button class="btn btn-primary table-btn me-2" data-id="${row.id}" data-action="edit">Edit</button>
                                <button class="btn btn-danger table-btn" data-id="${row.id}" data-action="delete">Delete</button>
                            </div>
                        `;
                    }
                }
            ],
            scrollY: '265px',  // Set the height for the vertical scrollbar
            scrollCollapse: true,  // Allow the table to collapse when the height is less than the specified scrollY
            responsive: true,
            autoWidth: false,  // Disable auto width calculation

        });

        table.on('init', function () {
            $(tableRef.current).closest('.dataTables_wrapper').find('div.dataTables_filter input').addClass('rounded');
            $(tableRef.current).closest('.dataTables_wrapper').find('div.dataTables_length').css('margin', '10px');
        });

        // Event listener for edit and delete buttons
        $(tableRef.current).on('click', '.btn.table-btn', function () {
            const action = $(this).data('action');
            const id = $(this).data('id');
            if (action === 'edit') {
                handleEditSensor(id);
            } else if (action === 'delete') {
                handleDelete(id);
            }
        });
    }

    const OnAddingSensor = async () => {
        try {
            if (!inputData.sensor_id) {
                toast.error("Sensor Id is required.");
                return;
            } else if (!inputData.sensor_name) {
                toast.error("Sensor Name is required.");
                return;
            }

            if (editMode) {
                if (!inputData.data_polling) {
                    toast.error("Data Polling Rate is required.");
                    return;
                }
                else if (!inputData.data_posting) {
                    toast.error("Data Posting Rate is required.");
                    return;
                }

                const response = await putToAPI(`userSensor/${EditedData.SensorID}/`, {
                    sensor_id: inputData.sensor_id,
                    sensor_name: inputData.sensor_name,
                    data_polling: inputData.data_polling,
                    data_posting: inputData.data_posting,
                    user_id: storedUserId,
                });

                if (response.status) {
                    fetchSensors(); // Fetch updated sensors data
                    toast.success("Sensor updated successfully", 'success');
                    handleCloseModalOne();
                }
            } else {
                // Check for duplicate sensor_id
                const isDuplicate = sensors.some(sensor => sensor.sensor_id === inputData.sensor_id);
                if (isDuplicate) {
                    toast.error("Sensor ID already exists.");
                    return;
                }

                const response = await postToAPI("userSensor/", {
                    sensor_id: inputData.sensor_id,
                    sensor_name: inputData.sensor_name,
                    user_id: storedUserId,
                });

                if (response.status) {
                    fetchSensors(); // Fetch updated sensors data
                    toast.success("Sensor added successfully", 'success');
                    handleCloseModalOne();
                }
            }
        } catch (error) {
            console.error("Error adding or updating sensor:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

         // Validate sensor_id to allow only alphanumeric characters
        if (name === 'sensor_id' && !/^[a-zA-Z0-9]*$/.test(value)) {
            return; // Do not update state if the value contains special characters
        }

        // Only allow numerical values for data_polling and data_posting fields
        if ((name === 'data_polling' || name === 'data_posting') && !/^\d*$/.test(value)) {
            return; // Do not update state if the value contains non-numeric characters
        }
        setInputData({ ...inputData, [name]: value });
    };

    const handleEditSensor = (id) => {
        const sensor = sensors.find(sensor => sensor.id === id);
        if (sensor) {
            setInputData({
                sensor_id: sensor.sensor_id,
                sensor_name: sensor.sensor_name,
                data_polling: sensor.data_polling,
                data_posting: sensor.data_posting,
            });
            setEditedData({ SensorID: id });
            setEditMode(true); // Set edit mode to true
            handleShowModalOne(); // Show the modal with pre-filled data
        }
    };

    const handleDelete = async (itemId) => {
        try {
            showAlert("Are you sure you want to delete this sensor?", "confirm", async (isConfirmed) => {
                if (!isConfirmed) {
                    return;
                }

                try {
                    const response = await deleteFromAPI(`userSensor/${itemId}/`);
                    if (response.message === "Item deleted successfully") {
                        setSensors(prevSensors => prevSensors.filter(sensor => sensor.id !== itemId));
                        toast.success('Sensor deleted successfully!', 'success');
                    } else {
                        console.error("Unexpected response:", response.message);
                    }
                } catch (error) {
                    console.error("Error deleting sensor:", error);
                }
            });
        } catch (error) {
            console.error("Error showing delete confirmation:", error);
        }

        setEditedData({ SensorID: '' });
    };

    const resetInputData = () => {
        setInputData({
            sensor_id: '',
            sensor_name: '',
            data_polling: '',
            data_posting: '',
        });
        setEditMode(false); // Reset edit mode
    };

    const ADDSensor = () => {
        resetInputData(); // Reset input data when opening the modal for adding
        handleShowModalOne(); // Show the modal
    };

    return (
        <>
            <div className="profile-container">
                <Sidebar />
                <Container>
                    {/* Container for user details */}
                    <div className="d-flex flex-column mb-3">
                        <Card.Title className="mb-3" style={{ fontSize: '2rem' }}>Welcome {First_Name} {Last_Name}</Card.Title>
                        <div className="user-details-container">
                            <p className="mb-1"><strong>Full Name: </strong>{First_Name} {Last_Name}</p>
                            <p className="mb-1"><strong>Phone Number: </strong>{Phone}</p>
                            <p className="mb-0"><strong>Email: </strong>{email}</p>
                        </div>
                    </div>
                </Container>
                <Container>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h2>Sensor Details</h2>
                        <button className="btn btn-primary logout-button" onClick={ADDSensor}>
                            <i className="bi bi-plus"></i> Add Sensor
                        </button>
                    </div>
                    <Table bordered responsive className="mb-0 table-nowrap w-100" ref={tableRef}>
                        <thead>
                            <tr>
                                <th>Sensor ID</th>
                                <th>Sensor Name</th>
                                <th>Data Posting</th>
                                <th>Data Polling</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* Sensors data will be rendered here */}
                        </tbody>
                    </Table>
                </Container>
                <Modal centered show={showModalOne} onHide={handleCloseModalOne} dialogClassName="modal-lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{editMode ? 'Edit Sensor Details' : 'Add Sensor Details'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Row>
                                <Col>
                                    <Form>
                                        <Col md={12}>
                                            <Form.Group className="custom-fr-group">
                                                <Form.Label>Sensor Name </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Sensor Name"
                                                    required
                                                    name="sensor_name"
                                                    value={inputData.sensor_name}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className="custom-fr-group">
                                                <Form.Label>Sensor ID</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Sensor ID"
                                                    rows={4}
                                                    name="sensor_id"
                                                    id="sensor_id"
                                                    value={inputData.sensor_id}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                        {editMode && (
                                            <>
                                                <Col md={12}>
                                                    <Form.Group className="custom-fr-group">
                                                        <Form.Label>Data Polling Rate (seconds) </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter Data Polling Time"
                                                            rows={4}
                                                            name="data_polling"
                                                            id="data_polling"
                                                            value={inputData.data_polling}
                                                            onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Group className="custom-fr-group">
                                                        <Form.Label>Data Posting Rate (seconds)</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter Data Posting Time"
                                                            rows={4}
                                                            name="data_posting"
                                                            id="data_posting"
                                                            value={inputData.data_posting}
                                                            onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </>
                                        )}
                                    </Form>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-0">
                        <Button variant="primary" onClick={OnAddingSensor}>
                            Ok
                        </Button>
                        <Button variant="secondary" onClick={handleCloseModalOne}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <ToastContainer position="top-left" autoClose={2000} hideProgressBar theme="colored" />
        </>
    );
}