import React, { useState, useEffect, useRef } from "react";
import { Card, Container, Form, Row, Col, Button } from "react-bootstrap";
import { postToAPI, getFromAPI } from './Utils/utils.js';
import { useNavigate } from "react-router-dom";
import { BiColor } from "react-icons/bi";
import { toast, ToastContainer } from "react-toastify";
import logo from "../images/Foogle-logo.png";

export default function LogIn() {
  const [inputData, setInputData] = useState({
    email: "",
    otp: ["", "", "", "", "", ""],
  });
  const [showOTPField, setShowOTPField] = useState(false);
  const [otpTimer, setOtpTimer] = useState(300); // 5 minutes in seconds
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const emailRef = useRef(null);
  const otpRefs = useRef([]);
  const navigate = useNavigate();

  useEffect(() => {
    let timer;
    if (showOTPField && otpTimer > 0) {
      timer = setTimeout(() => setOtpTimer(otpTimer - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [showOTPField, otpTimer]);

  useEffect(() => {
    if (showOTPField && otpRefs.current[0]) {
      otpRefs.current[0].focus();
    } else if (emailRef.current) {
      emailRef.current.focus();
    }
  }, [showOTPField]);

  const maskEmailOrPhone = (input) => {
    if (input.includes("@")) {
      const [localPart, domain] = input.split("@");
      const maskedLocal = localPart.length > 2 ? `${localPart[0]}${"*".repeat(localPart.length - 2)}${localPart.slice(-2)}` : localPart;
      return `${maskedLocal}@${domain}`;
    } else {
      return `${input.slice(0, 3)}******${input.slice(-2)}`;
    }
  };

  const handleRegenerateOtp = async (e) => {
    toast.success("OTP Sent", 'success');
    if (otpRefs.current[0]) {
      otpRefs.current[0].focus();
    }
    e.preventDefault();
    try {
      const response = await postToAPI("generate_otp/", {
        email: inputData.email,
      });
      if (response.status) {
        setInputData((prevData) => ({
          ...prevData,
          otp: ["", "", "", "", "", ""],
        }));
        setOtpTimer(300);
        setShowOTPField(true); // Show OTP fields after regeneration
        // Focus on the first OTP input
        
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const handleNext = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    setTimeout(() => setButtonDisabled(false), 5000); // Disable button for 5 seconds

    try {
      const response = await postToAPI("generate_otp/", {
        email: inputData.email,
      });
      if (response.status) {
        toast.success("OTP Sent", 'success');
        setShowOTPField(true);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      toast.error('Please enter your registered email or phone number', 'error');
    }
  };

  const handleSignup = () => {
    navigate("/SignUp");
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const otp = inputData.otp.join("");
      const response = await postToAPI("login/", {
        email: inputData.email,
        otp: otp,
      });
      if (response.status) {
        var parsedResponse = JSON.parse(response.data);
        localStorage.setItem('email', parsedResponse[0].fields.email);
        localStorage.setItem('user_id', parsedResponse[0].fields.user_id);
        localStorage.setItem('First_Name', parsedResponse[0].fields.First_Name);
        localStorage.setItem('Last_Name', parsedResponse[0].fields.Last_Name);
        localStorage.setItem('Phone', parsedResponse[0].fields.mobile_no);
        localStorage.setItem('isAdmin', parsedResponse[0].fields.isAdmin);
        const storedUserId = localStorage.getItem('user_id');
        const sensordata = await getFromAPI(`usersensor/?user_id=${encodeURIComponent(storedUserId)}`);
        if (sensordata.length === 0) {
          navigate("/Sensor");
        } else {
          navigate("/dashboard");
        }
      }
    } catch (error) {
      console.error("Error during login:", error);
      toast.error("Invalid OTP", 'error');
    }
  };

  const handleChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const handleOtpChange = (e) => {
    const { name, value } = e.target;
    const index = parseInt(name);

    if (e.key === "Backspace" && !value) {
      if (index > 0) {
        otpRefs.current[index - 1].focus();
      }
    } else {
      const newOtp = [...inputData.otp];
      newOtp[index] = value;
      setInputData({ ...inputData, otp: newOtp });

      if (value && index < 5) {
        otpRefs.current[index + 1].focus();
      }
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <>
      <div className="auth-bg py-2 py-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col sm={12} md={8} lg={6} xxl={4}>
              <Card>
                <Card.Body>
                  <div className="text-center mb-4">
                    <img src={logo} alt="Foogle Logo" style={{ width: "200px" }} />
                  </div>
                  <Card.Title className="text-center mt-3 mb-0" style={{ color: "blue" }}>{showOTPField ? "Enter OTP" : "Welcome Back!"}</Card.Title>
                  <p className="text-center text-muted">
                    {showOTPField ? `Please enter the OTP sent to your registered ${inputData.email.includes("@") ? "email" : "mobile number"}: ${maskEmailOrPhone(inputData.email)}` : "Please enter your details to login."}
                  </p>

                  <Form id="loginForm">
                    {!showOTPField && (
                      <Form.Group className="custom-fr-group" controlId="formBasicEmail">
                        <Form.Control
                          type="email"
                          placeholder="Email/Phone Number (with country code)"
                          name="email"
                          value={inputData.email}
                          onChange={handleChange}
                          ref={emailRef}
                        />
                      </Form.Group>
                    )}

                    {showOTPField && (
                      <Form.Group className="custom-fr-group">
                        <Row>
                          {inputData.otp.map((_, index) => (
                            <Col key={index}>
                              <Form.Control
                                type="text"
                                maxLength="1"
                                name={index.toString()}
                                id={`otp-${index}`}
                                value={inputData.otp[index]}
                                onChange={handleOtpChange}
                                onKeyDown={(e) => handleOtpChange(e)}
                                ref={(el) => (otpRefs.current[index] = el)}
                                required
                                style={{ textAlign: "center" }}
                              />
                            </Col>
                          ))}
                        </Row>
                        <Row className="mt-3">
                          <Col className="d-flex align-items-center">
                            {otpTimer > 0 && (
                              <span>Resend OTP in {formatTime(otpTimer)}</span>
                            )}
                             {otpTimer === 0 && ( 
                              <button className="btn btn-link" onClick={handleRegenerateOtp}>Resend OTP</button>
                             )} 
                          </Col>
                        </Row>
                      </Form.Group>
                    )}

                    {!showOTPField ? (
                      <>
                        <button className="btn btn-primary logout-button w-100" onClick={handleNext} disabled={buttonDisabled}>
                          <i className="bi bi-box-arrow-in-right me-2"></i>Login
                        </button>
                        <div className="text-center mt-3">
                          <span>Don't have an account? </span>
                          <button className="btn btn-link" onClick={handleSignup}>Sign up</button>
                        </div>
                      </>
                    ) : (
                      <button className="btn btn-primary logout-button w-100" onClick={handleLogin}>
                        <i className="bi bi-box-arrow-in-right me-2"></i>Login
                      </button>
                    )}
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer position="top-left" autoClose={2000} hideProgressBar theme="colored" />
      </>
  );
}
