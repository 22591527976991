import React, { useState } from 'react';
import { Offcanvas, Nav, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useNavigate, NavLink } from "react-router-dom";
import 'bootstrap-icons/font/bootstrap-icons.css'; // Ensure you have this imported for icons

export default function Sidebar() {
  const [show, setShow] = useState(false);
  const [expanded, setExpanded] = useState(false); // State to track if the sidebar is expanded

  const handleClose = () => {
    setShow(false);
    setExpanded(false);
  };

  const handleShow = () => {
    setShow(true);
    setExpanded(true);
  };

  const navigate = useNavigate();

  const handleLogout = () => {
    navigate('/');
  };
  const First_Name = localStorage.getItem('First_Name');
  const Last_Name = localStorage.getItem('Last_Name');

  const isAdmin = localStorage.getItem('isAdmin') === 'True';

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Minimize
    </Tooltip>
  );

  return (
    <>
      {!show && (
        <Button variant="primary" onClick={handleShow} className="hamburger-button custom-hamburger">
          <i className="bi bi-list"></i>
        </Button>
      )}

      <Offcanvas show={show} onHide={handleClose} className={`custom-sidebar ${expanded ? 'expanded' : 'collapsed'}`}>
        <Offcanvas.Header>
            <Offcanvas.Title style={{ marginRight: 'auto' }}>{First_Name} {Last_Name} !!</Offcanvas.Title>
              <OverlayTrigger placement="right" overlay={renderTooltip}>
            <Button variant="primary" onClick={handleClose} className="hamburger-button custom-hamburger">
              <i className="bi bi-list"></i>
            </Button>
          </OverlayTrigger>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav defaultActiveKey="/dashboard" className="flex-column custom-nav">
            <NavLink to="/dashboard" className="custom-nav-link">
              <i className="bi bi-speedometer2 me-2"></i>
              {expanded && 'Dashboard'}
            </NavLink>
            <NavLink to="/profile" className="custom-nav-link">
              <i className="bi bi-person me-2"></i>
              {expanded && 'Profile'}
            </NavLink>
            {isAdmin && (
              <NavLink to='/Admin' className="custom-nav-link">
                <i className="bi bi-person-badge me-2"></i>
                {expanded && 'Admin'}
              </NavLink>
            )}
            <Nav.Link onClick={handleLogout} className="custom-nav-link">
              <i className="bi bi-box-arrow-right me-2"></i>
              {expanded && 'Logout'}
            </Nav.Link>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
