import React, { useState, useEffect } from "react";
import { Card, Container, Form, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getFromAPI, postToAPI } from './Utils/utils.js'; // Updated import to use utility functions
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; // Import toastify CSS
import logo from "../images/Foogle-logo.png"; // Correctly import the logo image


export default function Sensor() {
  const [inputData, setInputData] = useState({
    sensor_id: "",
    sensor_name: "",
  });
  const [userId, setUserId] = useState("");
  const [errors, setErrors] = useState({});
  const [sensors, setSensors] = useState([]); // State to store existing sensors
  const navigate = useNavigate();
  
  const storedUserId = localStorage.getItem('user_id');

  useEffect(() => {
    fetchSensors(); // Fetch existing sensors on component mount
  }, []);

  const fetchSensors = async () => {
    try {
      const sensordata = await getFromAPI(`usersensor/?user_id=${encodeURIComponent(storedUserId)}`);
      setSensors(sensordata);
    } catch (error) {
      console.error("Error fetching sensor data:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Check if sensor_id or sensor_name is empty
    if (!inputData.sensor_id) {
      toast.error("Please enter sensor id.");
      return;
    }
    if (!inputData.sensor_name) {
      toast.error("Please enter sensor name.");
      return;
    }
  
    // Check for duplicate sensor_id
    const isDuplicate = sensors.some(sensor => sensor.sensor_id === inputData.sensor_id);
    if (isDuplicate) {
      toast.error("Sensor ID already exists.");
      return;
    }
    // If no duplicates, proceed to add the sensor
    try {
      const response = await postToAPI('userSensor/', {
        sensor_name: inputData.sensor_name,
        sensor_id: inputData.sensor_id,
        user_id: storedUserId,
      });
      
      if (response.status) {
        navigate("/dashboard");
        toast.success('Sensor added successfully');
      }
    } catch (error) {
      console.error("Error adding sensor:", error);
      toast.warning("Sensor ID already exists");
    }
  };
  
  

  return (
    <>
      <div className="auth-bg py-2 py-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col sm={12} md={8} lg={6} xxl={4}>
              <Card>
                <Card.Body>
                  <div className="text-center mb-4">
                    <img src={logo} alt="Foogle Logo" style={{ width: "200px" }} />
                  </div>
                  <Card.Title className="text-center mt-3 mb-0" style={{ color: "blue" }}>Add Sensor</Card.Title>
                  <p className="text-center text-muted">Add a sensor to continue.</p>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="custom-fr-group" controlId="formSensorName">
                      <Form.Label>Sensor Name <span className="text-danger">*</span></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Sensor Name"
                        name="sensor_name"
                        value={inputData.sensor_name}
                        onChange={handleChange}
                      />
                      
                    </Form.Group>
                    <Form.Group className="custom-fr-group" controlId="formSensorID">
                      <Form.Label>Sensor ID <span className="text-danger">*</span></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Sensor ID"
                        name="sensor_id"
                        value={inputData.sensor_id}
                        onChange={handleChange}
                      />
                      
                    </Form.Group>

                    <Button variant="primary" type="submit" className="w-100">
                      Add Sensor
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer position="top-left" autoClose={2000} hideProgressBar theme="colored" />
    </>
  );
}
