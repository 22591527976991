import React, { useState, useEffect, useRef } from "react";
import { Table, Form, Button, Card, Container, Row, Col, Modal } from "react-bootstrap";
import { getFromAPI, postToAPI, putToAPI, deleteFromAPI, showAlert } from '../Utils/utils.js';
import Sidebar from '../SideBar.js';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from "datatables.net-dt";
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import JSZip from "jszip";

export default function Admin() {
    const [sensors, setSensors] = useState([]);
    const [initialSensors, setInitialSensors] = useState([]); // Store initial sensor data
    const [showModalOne, setShowModalOne] = useState(false);
    const [mobileError, setMobileError] = useState("");

    const handleCloseModalOne = () => {
        setShowModalOne(false);
        resetInputData(); // Reset input fields and errors
    };

    const handleShowModalOne = () => setShowModalOne(true);

    const [inputData, setInputData] = useState({
        First_Name: '',
        Last_Name: '',
        email: '',
        mobile_no: '',
        user_id: '',
        isAdmin: false,
    });

    const [editMode, setEditMode] = useState(false);
    const [EditedData] = useState({
        ProjectStatusID: '',
    });

    const navigate = useNavigate();
    const tableRef = useRef(null);

    useEffect(() => {
        fetchSensors();
    }, []);

    useEffect(() => {
        initDataTable();
    }, [sensors]);

    async function fetchSensors() {
        try {
            const sensordata = await getFromAPI(`userdata/`);
            setSensors(sensordata);
            setInitialSensors(sensordata); // Store initial data
        } catch (error) {
            console.error("Error fetching sensor data:", error);
        }
    }

    

    DataTable.Buttons.jszip(JSZip);

    function initDataTable() {
        if ($.fn.DataTable.isDataTable(tableRef.current)) {
            $(tableRef.current).off('click', '.btn.table-btn'); // Remove event listeners
            $(tableRef.current).DataTable().destroy();
        }

        $(tableRef.current).DataTable({
            dom: '<"d-flex justify-content-between mb-3"lf>t<"d-flex justify-content-between mb-3"ip>',
            language: {
                search: '',
                searchPlaceholder: 'Search...',
                lengthMenu: 'Show _MENU_ entries per page  ',
                paginate: {
                    previous: '«',
                    next: '»',
                },
            },
            data: sensors,
            columns: [
                { data: 'First_Name' },
                { data: 'Last_Name' },
                { data: 'email' },
                { data: 'user_id'},
                { data: 'mobile_no' },
                {
                    data: 'isAdmin',
                    render: function (data, type, row) {
                        return data === "True" ? 'Admin' : 'Customer';
                    }
                },
                {
                    data: null,
                    render: function (data, type, row) {
                        return `
                            <div class="d-flex">
                                <button class="btn btn-primary table-btn me-2" data-id="${row.id}" data-action="edit">Edit</button>
                                <button class="btn btn-danger table-btn" data-id="${row.id}" data-action="delete">Delete</button>
                            </div>
                        `;
                    }
                }
            ],
            scrollY: '265px',
            scrollCollapse: true,
            responsive: true,
        });

        $(tableRef.current).closest('.dataTables_wrapper').find('div.dataTables_filter input').addClass('rounded');

        $(tableRef.current).on('click', '.btn.table-btn', function () {
            const action = $(this).data('action');
            const id = $(this).data('id');
            if (action === 'edit') {
                handleEditSensor(id);
            } else if (action === 'delete') {
                handleDelete(id);
            }
        });
    }

    const resetInputData = () => {
        setInputData({
            First_Name: '',
            Last_Name: '',
            email: '',
            mobile_no: '',
            user_id: '',
            isAdmin: false,
        });
        setEditMode(false);
        setMobileError("");
    };

    const OnAddingSensor = async () => {
        try {
            const trimmedFirstName = inputData.First_Name.trim();
            const trimmedLastName = inputData.Last_Name.trim();
            const trimmedEmail = inputData.email.trim();
            const trimmedMobileNo = inputData.mobile_no.trim();

            if (!trimmedFirstName) {
                toast.error("Please enter the first name", 'error');
                return;
            }
            if (!trimmedLastName) {
                toast.error("Please enter the last name", 'error');
                return;
            }
            if (!trimmedMobileNo) {
                toast.error("Please enter the phone number", 'error');
                return;
            }
            if (trimmedMobileNo.length !== 13) {
                toast.error('Entered phone number is invalid', 'error');
                return;
            }

            if (editMode) {
                const response = await putToAPI(`userdata/${EditedData.SensorID}/`, {
                    First_Name: trimmedFirstName,
                    Last_Name: trimmedLastName,
                    email: trimmedEmail,
                    mobile_no: trimmedMobileNo,
                    user_id: inputData.user_id,
                    isAdmin: inputData.isAdmin,
                });

                if (response.status) {
                    setSensors(response.data);
                    toast.success("Customer details updated successfully!", 'success');
                    handleCloseModalOne();
                }
            }
        } catch (error) {
            console.error("Error adding or updating Sensor:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setInputData({
            ...inputData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleEditSensor = (id) => {
        EditedData.SensorID = id;
        const sensor = sensors.find(sensor => sensor.id === id);
        if (sensor) {
            setInputData({
                First_Name: sensor.First_Name,
                Last_Name: sensor.Last_Name,
                mobile_no: sensor.mobile_no,
                email: sensor.email,
                user_id: sensor.user_id,
                isAdmin: sensor.isAdmin === "True",
            });
            setEditMode(true);
            handleShowModalOne();
        }
    };

    const handleDelete = async (itemId) => {
        try {
            const sensor = sensors.find(sensor => sensor.id === itemId);
            if (!sensor) {
                console.error("Sensor not found");
                return;
            }

            const confirmationMessage = sensor.isAdmin === 'True'
                ? "Are you sure you want to delete this Admin?"
                : "Are you sure you want to delete this Customer?";

            showAlert(confirmationMessage, "confirm", async (isConfirmed) => {
                if (!isConfirmed) {
                    return;
                }

                try {
                    const response = await deleteFromAPI(`userdata/${itemId}/`);

                    if (response.message === "Item deleted successfully") {
                        setSensors(response.data);
                        toast.success('Customer deleted successfully!', 'success');
                    } else {
                        console.error("Unexpected response:", response.message);
                    }
                } catch (error) {
                    console.error("Error deleting user:", error);
                }
            });
        } catch (error) {
            console.error("Error showing delete confirmation:", error);
        }

        EditedData.ProjectStatusID = '';
    };

    const handleLogout = () => {
        navigate('/');
    };

    return (
        <>
            <div className="admin-container">
                <Sidebar />
                <Container>
                </Container>
                <Container>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h2>Customer Details</h2>
                        <button className="btn btn-primary logout-button" onClick={handleLogout}>
                            <i className="bi bi-box-arrow-in-right me-2"></i>Logout
                        </button>
                    </div>
                    <Table bordered responsive className="mb-0" ref={tableRef}>
                        <thead>
                            <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>User ID</th>
                                <th>Phone Number</th>
                                <th>Position</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </Table>
                </Container>
                <Modal centered show={showModalOne} onHide={handleCloseModalOne} dialogClassName="modal-lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{editMode ? 'Edit Customer Details' : 'Add New Customer'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Row>
                                <Col>
                                    <Form>
                                        <Col md={12}>
                                            <Form.Group className="custom-fr-group">
                                                <Form.Label>First Name </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter First Name"
                                                    required
                                                    name="First_Name"
                                                    value={inputData.First_Name}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className="custom-fr-group">
                                                <Form.Label>Last Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Last Name..."
                                                    rows={4}
                                                    name="Last_Name"
                                                    value={inputData.Last_Name}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                        {editMode && (
                                            <>
                                                <Col md={12}>
                                                    <Form.Group className="custom-fr-group">
                                                        <Form.Label>Email</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter Email..."
                                                            rows={4}
                                                            name="email"
                                                            id="email"
                                                            value={inputData.email}
                                                            onChange={handleChange}
                                                            readOnly
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Group className="custom-fr-group">
                                                        <Form.Label>Phone Number</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter Phone Number..."
                                                            rows={4}
                                                            name="mobile_no"
                                                            id="mobile_no"
                                                            value={inputData.mobile_no}
                                                            onChange={handleChange}
                                                        />
                                                        {mobileError && <div className="text-danger">{mobileError}</div>}
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Group className="custom-fr-group">
                                                        <Form.Label>User ID</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            rows={4}
                                                            name="user_id"
                                                            id="user_id"
                                                            value={inputData.user_id}
                                                            onChange={handleChange}
                                                            readOnly
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Group className="custom-fr-group">
                                                        <Form.Check
                                                            type="checkbox"
                                                            label="Is Admin"
                                                            name="isAdmin"
                                                            checked={inputData.isAdmin}
                                                            onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </>
                                        )}
                                    </Form>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-0">
                        <Button variant="primary" onClick={OnAddingSensor}>
                            Ok
                        </Button>
                        <Button variant="secondary" onClick={handleCloseModalOne}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <ToastContainer position="top-left" autoClose={2000} hideProgressBar theme="colored" />
        </>
    );
}
