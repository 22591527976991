import React, { useState } from "react";
import { Card, Container, Form, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { postToAPI } from './Utils/utils.js';
import { toast, ToastContainer } from "react-toastify";
import logo from "../images/Foogle-logo.png"; // Correctly import the logo image

export default function SignUp() {
  const [inputData, setInputData] = useState({
    First_Name: "",
    Last_Name: "",
    mobile_no: "",
    email: "",
    isAdmin: false,
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobile_no") {
      // Allow only numbers and plus sign for the mobile number field
      if (/^\+?\d*$/.test(value)) {
        setInputData({ ...inputData, [name]: value });
      }
    } else {
      setInputData({ ...inputData, [name]: value });
    }
  };

  const addAccount = async () => {
    // Validate fields before submitting
    if (!inputData.First_Name || !inputData.First_Name.trim()) {
      toast.error('Please enter first name');
      return;
    }
    if (!inputData.Last_Name || !inputData.Last_Name.trim()) {
      toast.error('Please enter last name');
      return;
    }
    if (!inputData.email || !inputData.email.trim() || !validateEmail(inputData.email)) {
      toast.error('Please enter a valid email id');
      return;
    }
    if (!inputData.mobile_no || !inputData.mobile_no.trim() || !validateMobileNumber(inputData.mobile_no)) {
      toast.error('Please enter a valid phone number');
      return;
    }

    try {
      const response = await postToAPI("userdata/", {
        First_Name: inputData.First_Name,
        Last_Name: inputData.Last_Name,
        mobile_no: inputData.mobile_no,
        email: inputData.email,
      });

      if (response.status) {
        navigate("/");
        toast.success('Sign Up Successfully');
      }

    } catch (error) {
      console.error("Error adding account:", error);
      toast.error("Email or Phone number already registered");
    }
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const validateMobileNumber = (number) => {
    const re = /^\+\d{1,3}\d{10}$/;
    return re.test(String(number));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addAccount();
  };

  return (
    <>
      <div className="auth-bg py-2 py-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col sm={12} md={8} lg={6} xxl={4}>
              <Card>
                <Card.Body>
                  <div className="text-center mb-4">
                    <img src={logo} alt="Foogle Logo" style={{ width: "200px" }} />
                  </div>
                  <Card.Title className="text-center mt-3 mb-0" style={{ color: "blue" }}>Create an Account</Card.Title>
                  <p className="text-center text-muted">Please fill in the details to sign up.</p>

                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="custom-fr-group" controlId="formFirstName">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="First Name"
                        name="First_Name"
                        value={inputData.First_Name}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group className="custom-fr-group" controlId="formLastName">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Last Name"
                        name="Last_Name"
                        value={inputData.Last_Name}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group className="custom-fr-group" controlId="formMobileNumber">
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Phone Number (with country code)"
                        name="mobile_no"
                        value={inputData.mobile_no}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group className="custom-fr-group" controlId="formEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={inputData.email}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Button variant="primary" type="submit" className="w-100">
                      Sign Up
                    </Button>

                    <div className="text-center mt-3">
                      <span>Already have an account? </span>
                      <Button variant="link" onClick={() => navigate("/")}>Log in</Button>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer position="top-left" autoClose={2000} hideProgressBar theme="colored" />
    </>
  );
}
